import { defineComponent } from 'vue'
export default {
  computed: {
    // 列配置
    column(){
      let col=[]
      if (this.canSelect) {
        col.push({
          type: 'selection',
          // fixed: true
        })
      }
      col.push(
        {
          // type: 'index',
          label: '自选',
          width: 50,
          prop: 'isCollect',
          // fixed: true
          params: {
            onclick: this.clickCollect
          },
          component: defineComponent({
            props: ['row', 'col', 'index'],
            render(){
              return (
                <span onclick={()=>{this.col.params.onclick(this.row)}} style="cursor: pointer;font-size: 18px;font-weight: bold;">{this.row[this.col.property] && '-' || '+' }</span>
              )
            }
          })
        },
        {
          // type: 'index',
          label: '序号',
          width: 50,
          prop: 'index'
          // fixed: true
        },
        {
          prop: '转债代码',
          label: '代码',
          // fixed: true,
          width: 110,
          clickFn: this.openRealTimeQuotes,
          component: defineComponent({
            props: ['row', 'col'],
            methods: {
              getStatus(){
                const cell = this.row
                if (cell.redeem_icon=='R') {
                  // 强赎
                  return {
                    type: 'redeem',
                    html: cell.force_redeem,
                    icon: 'el-icon-warning-outline'
                  }
                } else if (cell.adj_scnt>=1) {
                  // 下修
                  return {
                    type: 'adjusted',
                    html: `转股价下修${cell.adj_cnt}次，成功下修${cell.adj_scnt}次`,
                    icon: 'el-icon-warning-outline'
                  }
                } else if (cell['强赎修正'] > 30) {
                  return {
                    type: 'qsxz',
                    html: `离强赎还有${cell['强赎修正']-30}天`,
                    icon: 'el-icon-warning-outline'
                  }
                }
                return {}
              },
            },
            render(){
              return (
                <a class={['other_link', this.getStatus().type]} target="_blank" href={`http://xueqiu.com/S/${this.row[this.col.property]}` } onClick={(e)=>{this.col.clickFn({e, type:'bond' ,row:this.row})}}>
                  {this.row[this.col.property]}
                  <el-tooltip 
                    class="item" 
                    effect="dark" 
                    content={this.getStatus().html} 
                    placement="right"
                    disabled={!this.getStatus().html}
                  >
                    <i class={this.getStatus().icon}></i>
                  </el-tooltip>
                  
                </a>
              )
            }
          })
        },
        {
          prop: '债券简称',
          label: '转债名称',
          // fixed: true,
          width: 100,
          clickFn: this.openRealTimeQuotes,
          component: defineComponent({
            props: ['row', 'col'],
            methods: {
              getStatus(){
                const cell = this.row
                if (cell.redeem_icon=='R') {
                  // 强赎
                  return {
                    type: 'redeem',
                    html: cell.force_redeem,
                    icon: 'el-icon-warning-outline'
                  }
                } else if (cell.adj_scnt>=1) {
                  // 下修
                  return {
                    type: 'adjusted',
                    html: `转股价下修${cell.adj_cnt}次，成功下修${cell.adj_scnt}次`,
                    icon: 'el-icon-warning-outline'
                  }
                } else if (cell['强赎修正'] > 30) {
                  return {
                    type: 'qsxz',
                    html: `离强赎还有${cell['强赎修正']-30}天`,
                    icon: 'el-icon-warning-outline'
                  }
                }
                return {}
              },
            },
            render(){
              // return (<a class="other_link" target="_blank" href={`http://xueqiu.com/S/${this.row['转债代码']}`}>{this.row[this.col.property]}</a>)

              return (
                <a class={['other_link', this.getStatus().type]} target="_blank" href={`http://xueqiu.com/S/${this.row['转债代码']}`} onClick={(e)=>{this.col.clickFn({e, type:'bond' ,row:this.row})}}>
                  {this.row[this.col.property]}
                  <el-tooltip 
                    class="item" 
                    effect="dark" 
                    content={this.getStatus().html} 
                    placement="right"
                    disabled={!this.getStatus().html}
                  >
                    <i class={this.getStatus().icon}></i>
                  </el-tooltip>
                  
                </a>
              )
            }
          })
        },
        {
          prop: '转债价格',
          label: '现价',
          sortable: 'custom',

        },
        {
          prop: '转债涨跌幅',
          label: '涨跌幅',
          sortable: 'custom',
          component: defineComponent({
            props: ['col', 'row'],
            render(){
              const result = this.row[this.col.property]
              if (result > 0) {
                return (<a style="color:red">{this.row[this.col.property]}%</a>)
              } else if (result < 0) {
                return (<a style="color:green">{this.row[this.col.property]}%</a>)
              }
              return (<a>{this.row[this.col.property]}</a>)
            }
          })
        },
        {
          prop: '正股简称',
          label: '正股名称',
          clickFn: this.openRealTimeQuotes,
          component: defineComponent({
            props: ['row', 'col'],
            render(){
              return (<a class="other_link" target="_blank" href={`http://xueqiu.com/S/${this.row['股票代码']}`} onClick={(e)=>{this.col.clickFn({e, type:'stock' ,row:this.row})}}>{this.row[this.col.property]}</a>)
            }
          })
        },
        // {
        //   prop: '股价',
        //   label: '正股现价',
        //   sortable: 'custom'
        // },
        {
          prop: '正股涨跌幅',
          label: '正股涨跌幅',
          // width: 300
          'min-width': 100,
          sortable: 'custom',
          component: defineComponent({
            props: ['col', 'row'],
            render(){
              const result = this.row[this.col.property]
              if (result > 0) {
                return (<a style="color:red">{this.row[this.col.property]}%</a>)
              } else if (result < 0) {
                return (<a style="color:green">{this.row[this.col.property]}%</a>)
              }
              return (<a>{this.row[this.col.property]}</a>)
            }
          })
        },
        {
          prop: '转股溢价率',
          label: '溢价率',
          sortable: 'custom',
          formatter:(row, column, cellValue, index)=>{
            return (row[column.property] * 100).toFixed(2) + '%'
          }
        },
        {
          prop: '价值溢价',
          label: '价值溢价',
          sortable: 'custom',
          formatter:(row, column, cellValue, index)=>{
            // return (row[column.property] * 100).toFixed(2) + '%'
            return (row[column.property]).toFixed(2)
          }
        },
        {
          prop: '传统双低',
          label: '传统双低',
          sortable: 'custom',
          formatter:(row, column, cellValue, index)=>{
            // return (row[column.property] * 100).toFixed(2) + '%'
            return (row[column.property]).toFixed(2)
          }
        },
      )
      const handle={
        multiFactor: ()=>{
          col.push(...[
            {
              prop: '正股趋势',
              label: '正股趋势',
              // sortable: 'custom',
              // formatter:(row, column, cellValue, index)=>{
              //   const cache=row[column.property]
              //   return cache && parseInt(cache) || '-'
              // }
            },
            {
              prop: '综合分数',
              label: '综合评分',
              sortable: 'custom',
              'class-name': 'comprehensive_rating',
              formatter:(row, column, cellValue, index)=>{
                const val=row[column.property]
                if (val) {
                  if (val!='申请数据可见') return (row[column.property] * 100).toFixed(2) + '%'
                  return val
                } else {
                  return '-'
                }
              }
            },
          ])

        },
        'multiFactor_v1_1': ()=>{
          col.push(...[
            {
              prop: '正股趋势',
              label: '正股趋势',
              // sortable: 'custom',
              // formatter:(row, column, cellValue, index)=>{
              //   const cache=row[column.property]
              //   return cache && parseInt(cache) || '-'
              // }
            },
            
            {
              prop: '多因子V1.1',
              label: '多因子V1.1',
              sortable: 'custom',
              'class-name': 'comprehensive_rating',
              formatter:(row, column, cellValue, index)=>{
                const val=row[column.property]
                if (val) {
                  if (val!='申请数据可见') return (row[column.property] * 100).toFixed(2) + '%'
                  return val
                } else {
                  return '-'
                }
              }
            },
          ])
        },
        fiveFactor: ()=>{
          col.push(...[
            
            {
              prop: '正股趋势',
              label: '正股趋势',
              // sortable: 'custom',
              // formatter:(row, column, cellValue, index)=>{
              //   const cache=row[column.property]
              //   return cache && parseInt(cache) || '-'
              // }
            },
            
            {
              prop: '五因子分数',
              label: '五因子分数',
              sortable: 'custom',
              'class-name': 'comprehensive_rating',
              formatter:(row, column, cellValue, index)=>{
                const val=row[column.property]
                if (val) {
                  if (val!='申请数据可见') return (row[column.property] * 100).toFixed(2) + '%'
                  return val
                } else {
                  return '-'
                }
              }
            },
            
          ])
    
        },
        dbLow: ()=>{
          col.push(...[
            
            {
              prop: '涨幅对标',
              label: '涨幅对标',
              sortable: 'custom',
              formatter:(row, column, cellValue, index)=>{
                const cache=row[column.property]
                return cache && (cache*1).toFixed(2) || '-'
              }
            },
            {
              prop: '正股趋势',
              label: '正股趋势',
              // sortable: 'custom',
              // formatter:(row, column, cellValue, index)=>{
              //   const cache=row[column.property]
              //   return cache && parseInt(cache) || '-'
              // }
            },
            {
              prop: '双低分数',
              label: '双低分数',
              sortable: 'custom',
              'class-name': 'comprehensive_rating',
              formatter:(row, column, cellValue, index)=>{
                const val=row[column.property]
                if (val) {
                  if (val!='申请数据可见') return (row[column.property] * 100).toFixed(2) + '%'
                  return val
                } else {
                  return '-'
                }
              }
            },
            
          ])
    
        },
        power: ()=>{
          col.push(...[
            {
              prop: 'yj_bias',
              label: '溢价偏离率',
              sortable: 'custom',
              formatter:(row, column, cellValue, index)=>{
                const cache=row[column.property]
                return cache && (cache*1).toFixed(2) || '-'
              }
            },
            {
              prop: '正股趋势',
              label: '正股趋势',
              // sortable: 'custom',
              // formatter:(row, column, cellValue, index)=>{
              //   const cache=row[column.property]
              //   return cache && parseInt(cache) || '-'
              // }
            },
          
            {
              prop: '强势分数',
              label: '超低分数',
              sortable: 'custom',
              'class-name': 'comprehensive_rating',
              formatter:(row, column, cellValue, index)=>{
                const val=row[column.property]
                if (val) {
                  if (val!='申请数据可见') return (row[column.property] * 100).toFixed(2) + '%'
                  return val
                } else {
                  return '-'
                }
              }
            },
            
          ])
    
        }
      }
      handle[this.type]()

      // 扩展
      if (this.isExtend) {
        col.push(...[
          {
            prop: 'rating_cd',
            label: '评级',
            sortable: 'custom'
          },
          {
            prop: 'D',
            label: 'D',
            sortable: 'custom',
            formatter:(row, column, cellValue, index)=>{
              const cache=row[column.property]
              return cache && parseInt(cache) || '-'
            } 
          },
          {
            prop: '转债余额',
            label: '转债余额(亿元)',
            sortable: 'custom',
            formatter:(row, column, cellValue, index)=>{
              const cache=row[column.property]
              return cache && (cache*1).toFixed(2) || '-'
            }
          },
          {
            prop: '纯债价值',
            label: '纯债价值',
            sortable: 'custom',
            formatter:(row, column, cellValue, index)=>{
              const cache=row[column.property]
              return cache && (cache*1).toFixed(2) || '-'
            }
          },
          {
            prop: '期权价值',
            label: '期权价值',
            sortable: 'custom',
            formatter:(row, column, cellValue, index)=>{
              const cache=row[column.property]
              return cache && (cache*1).toFixed(2) || '-'
            }
          },
          {
            prop: 'vix',
            label: '波动率',
            sortable: 'custom',
            formatter:(row, column, cellValue, index)=>{
              return (row[column.property] * 100).toFixed(2) + '%'
            }
          },
        ])
      }

      // 收藏列表
      if (this.isOnlyCollect) {
        col=col.filter(c=>{
          return !['综合评分', '多因子V1.1', '五因子分数', '双低分数', '超低分数'].includes(c.label)
        })
        col.push(
          {
            prop: 'bond_price',
            label: '进入价格',
          },
          {
            prop: 'bond_change',
            label: '目前涨跌幅',
            component: defineComponent({
              props: ['col', 'row'],
              render(){
                const result = this.row[this.col.property]
                if (result > 0) {
                  return (<a style="color:red">{this.row[this.col.property]}%</a>)
                } else if (result < 0) {
                  return (<a style="color:green">{this.row[this.col.property]}%</a>)
                }
                return (<a>{this.row[this.col.property]}</a>)
              }
            })
          }
        )
      }
      return col

    },
  }
}